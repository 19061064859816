import { Loader } from '@/components/Loader/Loader';
import { setSearchActive } from '@/store';
import { ProviderProps } from '@/types';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export function WithLoader({ children }: ProviderProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const previousUrl = useRef(router.asPath);

  function removeLocaleFromUrl(url: string) {
    return url.replace(/\/(ru|en)(\/|$)/, '/');
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    const saveScrollPosition = (url: string) => {
      const cleanedUrl = removeLocaleFromUrl(url);
      sessionStorage.setItem(
        `scrollPosition-${cleanedUrl}`,
        JSON.stringify({ x: window.scrollX, y: window.scrollY }),
      );
    };

    const restoreScrollPosition = (url: string) => {
      const cleanedUrl = removeLocaleFromUrl(url);
      const scrollPosition = sessionStorage.getItem(
        `scrollPosition-${cleanedUrl}`,
      );

      if (scrollPosition) {
        const { x, y } = JSON.parse(scrollPosition);
        setTimeout(() => {
          window.scrollTo(x, y);
          window.scroll(x, y);
          window.requestAnimationFrame(() => window.scrollTo(x, y));
        }, 100);
      } else {
        window.scrollTo(0, 0);
      }
    };

    const handleStart = () => {
      dispatch(setSearchActive(false));
      saveScrollPosition(router.asPath);
      setLoading(true);
    };

    const handleComplete = (url: string) => {
      if (typeof url !== 'string') {
        return;
      }
      setLoading(false);
      restoreScrollPosition(url);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    router.beforePopState(({ url }) => {
      saveScrollPosition(router.asPath);
      restoreScrollPosition(url);
      return true;
    });

    previousUrl.current = router.asPath;

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
      router.beforePopState(() => true);
    };
  }, [router, dispatch]);

  if (loading) return <Loader fullScreen />;

  return children;
}
